define("discourse/plugins/discourse-tickets/discourse/initializers/discourse-tickets-intializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/lib/utilities", "discourse/plugins/discourse-tickets/discourse/lib/ticket-utilities", "@ember/object/computed", "@ember/object", "@ember/runloop"], function (_exports, _pluginApi, _decorators, _utilities, _ticketUtilities, _computed, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'discourse-tickets-initializer',
    initialize(container) {
      const currentUser = container.lookup('current-user:main');
      const siteSettings = container.lookup("site-settings:main");
      (0, _pluginApi.withPluginApi)('0.8.13', api => {
        var _dec, _dec2, _obj;
        api.modifyClass('component:mini-tag-chooser', (_dec = (0, _decorators.default)("tags"), _dec2 = (0, _decorators.default)("tags.[]", "filter", "highlightedSelection.[]"), (_obj = {
          willComputeAsyncContent(content) {
            // forbidden tickets are added manually, so we remove them manually
            if (content && content[0] && (0, _ticketUtilities.isTicketTag)(content[0].id)) {
              content.shift();
            }
            return content;
          },
          selection(tags) {
            return this._super(tags).filter(t => !(0, _ticketUtilities.isTicketTag)(t.value));
          },
          collectionHeader(tags, filter, highlightedSelection) {
            if (!(0, _computed.isEmpty)(tags)) {
              let output = "";
              if (tags.length >= 20) {
                tags = tags.filter(t => t.indexOf(filter) >= 0);
              }

              // discourse-tickets addition
              tags = tags.filter(t => !(0, _ticketUtilities.isTicketTag)(t));
              //

              tags.map(tag => {
                tag = (0, _utilities.escapeExpression)(tag);
                const isHighlighted = highlightedSelection.map(s => (0, _object.get)(s, "value")).includes(tag);
                output += `
                <button aria-label="${tag}" title="${tag}" class="selected-tag ${isHighlighted ? "is-highlighted" : ""}" data-value="${tag}">
                  ${tag}
                </button>
              `;
              });
              return `<div class="selected-tags">${output}</div>`;
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "selection", [_dec], Object.getOwnPropertyDescriptor(_obj, "selection"), _obj), _applyDecoratedDescriptor(_obj, "collectionHeader", [_dec2], Object.getOwnPropertyDescriptor(_obj, "collectionHeader"), _obj)), _obj)));

        // eventually this should be replaced with a filter in lib/render-tags
        // (currently have to override the entire library to achieve this)
        let hideTicketTags = function () {
          (0, _runloop.scheduleOnce)('afterRender', () => {
            $('.discourse-tags').children().each(function () {
              if ($(this).hasClass('discourse-tag') && !$(this).hasClass('ticket') && !$(this).hasClass('assigned-to')) {
                let tag = $(this).text();
                if ((0, _ticketUtilities.isTicketTag)(tag)) $(this).hide();
              }
            });
          });
        };
        api.addTagsHtmlCallback(topic => {
          if (topic.is_ticket && topic.tags && currentUser && currentUser.staff) {
            hideTicketTags();
            const icon = siteSettings.tickets_icon;
            const ticketTags = topic.tags.filter(t => (0, _ticketUtilities.isTicketTag)(t));
            let html = `<i class='fa fa-${icon} ticket-icon'></i>`;
            ticketTags.forEach(t => {
              let group = (0, _ticketUtilities.ticketTagGroup)(t);
              html += `<a href='/admin/tickets?filters=${group}:${t}' class='ticket discourse-tag simple'>${t}</a>`;
            });
            return html;
          }
        });
        if (siteSettings.assign_enabled) {
          api.modifyClass('route:user-activity-assigned', {
            redirect() {
              if (siteSettings.tickets_redirect_assigned) {
                const username = this.modelFor("user").get("username_lower");
                this.replaceWith('adminTickets', {
                  queryParams: {
                    filters: `assigned:${username}`
                  }
                });
              }
            }
          });
          api.modifyClass('route:user-private-messages-assigned', {
            redirect() {
              if (siteSettings.tickets_redirect_assigned) {
                const username = this.modelFor("user").get("username_lower");
                this.replaceWith('adminTickets', {
                  queryParams: {
                    filters: `assigned:${username}`
                  }
                });
              }
            }
          });
        }
      });
    }
  };
});