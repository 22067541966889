define("discourse/plugins/discourse-tickets/discourse/lib/ticket-utilities", ["exports", "discourse/models/site"], function (_exports, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allTicketTags = void 0;
  _exports.generateSelectKitContent = generateSelectKitContent;
  _exports.ticketTagGroup = _exports.isTicketTag = _exports.generateValueMap = void 0;
  let allTicketTags = function (site) {
    const ticketTags = site.ticket_tags;
    let result = [];
    if (ticketTags) {
      return result.concat.apply([], Object.keys(ticketTags).map(type => {
        return ticketTags[type];
      }));
    } else {
      return result;
    }
  };
  _exports.allTicketTags = allTicketTags;
  const isTicketTag = function (tag) {
    let site = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _site.default.current();
    return allTicketTags(site).indexOf(tag) > -1;
  };
  _exports.isTicketTag = isTicketTag;
  const ticketTagGroup = function (tag) {
    const ticketTags = _site.default.currentProp('ticket_tags');
    let group = null;
    Object.keys(ticketTags).forEach(g => {
      if (ticketTags[g].indexOf(tag) > -1) {
        group = g;
      }
    });
    return group;
  };
  _exports.ticketTagGroup = ticketTagGroup;
  const generateValueMap = function (tickets) {
    let valueMap = {
      tag: [],
      status: [],
      priority: [],
      reason: [],
      assigned: []
    };
    tickets.forEach(t => {
      Object.keys(valueMap).forEach(field => {
        if (field === 'assigned') {
          if (t[field] && valueMap[field].indexOf(t[field].user.username) === -1) {
            valueMap[field].push(t[field].user.username);
          }
        } else if (field === 'tag') {
          t['tags'].forEach(tag => {
            if (tag && valueMap[field].indexOf(tag) === -1) {
              valueMap[field].push(tag);
            }
          });
        } else {
          if (t[field] && valueMap[field].indexOf(t[field]) === -1) {
            valueMap[field].push(t[field]);
          }
        }
      });
    });
    return valueMap;
  };
  _exports.generateValueMap = generateValueMap;
  function generateSelectKitContent(content) {
    if (!content) return [];
    return content.map(i => ({
      id: i,
      name: i
    }));
  }
});