define("discourse/plugins/discourse-tickets/discourse/routes/admin-tickets", ["exports", "discourse/lib/ajax", "discourse/plugins/discourse-tickets/discourse/lib/ticket-utilities", "@ember/routing/route", "@ember/array"], function (_exports, _ajax, _ticketUtilities, _route, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model(params) {
      let data = {};
      if (params['order']) {
        data['order'] = params['order'];
      }
      let currentFilters = [];
      if (params['filters']) {
        currentFilters = params['filters'].split(',').map(f => {
          let arr = f.split(':');
          return {
            "field": arr[0],
            "value": arr[1]
          };
        });
        data['filters'] = params['filters'];
      }
      if (params['page']) {
        data['page'] = params['page'];
      }
      if (params['per_page']) {
        data['per_page'] = params['per_page'];
      }
      this.set('currentFilters', (0, _array.A)(currentFilters));
      return (0, _ajax.ajax)('/tickets', {
        data
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        currentFilters: this.get('currentFilters'),
        tickets: model.tickets,
        total: model.total,
        perPage: model.per_page,
        page: model.page,
        order: null,
        asc: null,
        valueMap: (0, _ticketUtilities.generateValueMap)(model.tickets)
      });
    },
    actions: {
      deactivate() {
        this.set('currentFilters', null);
        this.controllerFor('admin-tickets').set('currentFilters', null);
      }
    }
  });
});