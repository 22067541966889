define("discourse/plugins/discourse-tickets/discourse/templates/connectors/admin-menu/nav-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{nav-item route='adminTickets' label='tickets.nav_button_title'}}
  
  */
  {
    "id": "8mGsEG3Q",
    "block": "[[[1,[28,[35,0],null,[[\"route\",\"label\"],[\"adminTickets\",\"tickets.nav_button_title\"]]]],[1,\"\\n\"]],[],false,[\"nav-item\"]]",
    "moduleName": "discourse/plugins/discourse-tickets/discourse/templates/connectors/admin-menu/nav-item.hbs",
    "isStrictMode": false
  });
});