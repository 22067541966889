define("discourse/plugins/discourse-tickets/discourse/connectors/edit-topic/tickets-controls-container", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      const user = component.currentUser;
      const enabled = this.siteSettings.tickets_enabled;
      component.set('showTopicTicket', user.staff && enabled);
      (0, _runloop.scheduleOnce)('afterRender', () => {
        $('.tickets-controls-container').parent().addClass('tickets-controls-container-outlet');
      });
    }
  };
});